import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import infoIcon from '../../../../assets/common/info-icon.svg';

import blackGasStationImage from '../../../../assets/opel/image/gas-station-black-64.png';
import gasStationImage from '../../../../assets/opel/image/gas-station.png';
import thankYou from '../../../../assets/opel/image/thank_you.png';
import videoSmallThumbnailBase from '../../../../assets/opel/image/video-small-thumbnail-base.png';
import videoSmallThumbnailHover from '../../../../assets/opel/image/video-small-thumbnail-hovered.png';
import videoThumbnailBase from '../../../../assets/opel/image/video-thumbnail-base.png';
import videoThumbnailHover from '../../../../assets/opel/image/video-thumbnail-hovered.png';

import arrow_right from '../../../../assets/opel/image/right_arrow.svg';
import arrow_right_black from '../../../../assets/opel/image/right_arrow_black.svg';

import tlpContactUs from '../../../../assets/opel/image/tlp/contact_us.svg';
import tlpColorIcon from '../../../../assets/opel/image/tlp/icon-color.png';
import tlpEngineIcon from '../../../../assets/opel/image/tlp/icon-engine.png';
import tlpFuelIcon from '../../../../assets/opel/image/tlp/icon-fuel.png';
import tlpTransmissionIcon from '../../../../assets/opel/image/tlp/icon-transmission.png';
import tlpPromotionPersonalContact from '../../../../assets/opel/image/tlp/promotion_personal_contact.png';
import tlpPromotionPriceOffer from '../../../../assets/opel/image/tlp/promotion_price_offer.png';
import tlpPromotionService from '../../../../assets/opel/image/tlp/promotion_service.png';
import tlpPromotionTestDrive from '../../../../assets/opel/image/tlp/promotion_test_drive.png';

import poiActive from '../../../../assets/opelnew/icon/poiActive.svg';
import poiInActive from '../../../../assets/opelnew/icon/poiInActive.svg';
import locationChevronRight from '../../../../assets/opelnew/icon/locationChevronRight.svg';
import slashYellow from '../../../../assets/opelnew/icon/slashYellow.png';
import locationPhone from '../../../../assets/opelnew/icon/locationPhone.svg';
import locationListPhone from '../../../../assets/opelnew/icon/locationListPhone.svg';
import locationEmail from '../../../../assets/opelnew/icon/locationEmail.svg';
import locationInfo from '../../../../assets/opelnew/icon/infoIcon.svg';

import opelPartsStoreIcon from '../../../../assets/opelnew/icon/services/parts.svg';
import opelPassengerCarsSalesIcon from '../../../../assets/opelnew/icon/services/passengercarsales.svg';
import opelPassengerCarsServiceIcon from '../../../../assets/opelnew/icon/services/service.svg';
import opelUsedCarsIcon from '../../../../assets/opelnew/icon/services/usedcars.svg';
import docExtension from '../../../../assets/opelnew/icon/extension.svg';

import downloadFile from '../../../../assets/opelnew/icon/downloadFile.svg';
import galleryHover from '../../../../assets/opelnew/icon/gallery-open.svg';
import calendar from '../../../../assets/opelnew/icon/calendar_opelnew.svg';
import paypal_icon from '../../../../assets/common/reservation/paypal.svg';
import masterCardIconSvg from '../../../../assets/common/reservation/mastercard.svg';
import maestroCardIconPng from '../../../../assets/common/reservation/maestro.png';
import amexCardIconPng from '../../../../assets/common/reservation/american_express.png';
import visaCardIconPng from '../../../../assets/common/reservation/visa.png';
import googlePayIcon from '../../../../assets/common/reservation/google-pay.svg';
import applePayIconSvg from '../../../../assets/common/reservation/apple_pay.svg';

import formDateInput from '../../../../assets/opelnew/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/opelnew/icon/form-time-input.svg';

import reservedTag from '../../../../assets/opelnew/icon/reservedTag.svg';

import deleteIcon from '../../../../assets/opelnew/icon/delete.svg';
import prevArrowLightWithBackground from '../../../../assets/opelnew/icon/prevArrowLightWithBackground.svg';
import nextArrowLightWithBackground from '../../../../assets/opelnew/icon/nextArrowLightWithBackground.svg';
import infoIconGray from '../../../../assets/opelnew/icon/info_icon_gray.svg';
import infoIconNew from '../../../../assets/opelnew/icon/info-icon.svg';

const tlpAssets = {
  tlpContactUs,
  tlpColorIcon,
  tlpEngineIcon,
  tlpFuelIcon,
  tlpTransmissionIcon,
  tlpPromotionPersonalContact,
  tlpPromotionPriceOffer,
  tlpPromotionTestDrive,
  tlpPromotionService,
};

const newAssets = {
  poiActive,
  poiInActive,
  locationChevronRight,
  slashYellow,
  opelPartsStoreIcon,
  opelPassengerCarsSalesIcon,
  opelPassengerCarsServiceIcon,
  opelUsedCarsIcon,
  locationPhone,
  locationEmail,
  locationListPhone,
  locationInfo,
  downloadFile,
  galleryHover,
  calendar,
  formDateInput,
  formTimeInput,
  reservedTag,
  deleteIcon,
  prevArrowLightWithBackground,
  nextArrowLightWithBackground,
  infoIconNew,
};

export const opelAssets = Object.freeze({
  noImagePlaceholder,
  infoIcon,
  infoIconGray,
  docExtension,
  thankYou,
  videoSmallThumbnailBase,
  videoSmallThumbnailHover,
  videoThumbnailBase,
  videoThumbnailHover,
  gasStationImage,
  blackGasStationImage,
  arrow_right,
  arrow_right_black,
  paypal_icon,
  masterCardIconSvg,
  maestroCardIconPng,
  amexCardIconPng,
  visaCardIconPng,
  googlePayIcon,
  applePayIconSvg,
  ...tlpAssets,
  ...newAssets,
});
