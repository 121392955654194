import '../common/assets/citroen/cit-assets';
import '../common/assets/ds/ds-assets';
import '../common/assets/opel/opel-assets';
import '../common/assets/peugeot/peu-assets';
import '../common/assets/ssangyong/ssg-assets';
import '../common/assets/isuzu/isu-assets';
import '../common/assets/admin/admin-assets';
import '../common/assets/fiat/fiat-assets';
import '../common/assets/jeep/jeep-assets';
import '../common/assets/alfa/alfa-assets';
import '../common/assets/abarth/abarth-assets';

import 'react-toastify/dist/ReactToastify.css?url';
import 'react-datepicker/dist/react-datepicker.css?url';
import 'simplebar/dist/simplebar.min.css?url';
import 'react-tooltip/dist/react-tooltip.css?url';

import { addEventListener, removeEventListener } from '../common/events/module-event-listeners';
import { ExternalName } from '../common/general/module/brand-loader';
import { FormType } from '../common/form/common/form.store';
import { PageType } from '../admin/vacs-admin-manual-vehicles/vacs-admin-manual-vehicle.model';
import { EnvironmentType } from '../common/environment.service';

export type Brand =
  | 'opel'
  | 'peugeot'
  | 'ds'
  | 'citroen'
  | 'opelnew'
  | 'ssangyong'
  | 'isuzu'
  | 'admin'
  | 'fiat'
  | 'alfaromeo'
  | 'jeep'
  | 'abarth';

export interface ModuleSpecificOptions {
  vehicleId?: string;
  useThankYouPageRedirect?: boolean;
  isStandaloneForm?: boolean;
  isSidebarForm?: boolean;
  formSubmitButtonNeeded?: boolean;
  formTitleNeeded?: boolean;
}

export interface DWTComponentOptions extends ModuleSpecificOptions {
  apiKey?: string;
  setting?: string;
  additionalQueryParams?: { key: string; value: any }[];
  devMode?: boolean;
  adminMode?: boolean;
  previewMode?: boolean;
  useVacs?: boolean;
  isCmsAvailable?: boolean;
  pageType?: PageType;
  customEnvironment: EnvironmentType;
  tooltipContainerId?: string;
  brand?: Brand;
  customHeaders?: Record<string, string>;
  group_id?: string | number;
  shiroSessionId?: string | undefined;
  amAuthUserSessionId?: string | undefined;
  callbackFnName?: string | undefined;
  formType?: FormType;
  customData?: any | undefined;
}

const moduleRendererImport = import('./module-renderer');

interface ModuleRenderer {
  render: (element: Element, options: DWTComponentOptions) => any;
}

function createRenderer(moduleName: ExternalName): ModuleRenderer {
  return {
    render: async (element: Element, options: DWTComponentOptions) => {
      const moduleRenderer = await moduleRendererImport;
      await moduleRenderer.renderModule(moduleName, element, options);
    },
  };
}

export const DWTApp: Readonly<Record<ExternalName, ModuleRenderer>> = Object.freeze({
  VACS: createRenderer('VACS'),
  Showroom: createRenderer('Showroom'),
  CarConfigurator: createRenderer('CarConfigurator'),
  FinanceCalculator: createRenderer('FinanceCalculator'),
  OfferLandingPage: createRenderer('OfferLandingPage'),
  CarSearchWidget: createRenderer('CarSearchWidget'),
  OfferWidget: createRenderer('OfferWidget'),
  WelcomeTextWidget: createRenderer('WelcomeTextWidget'),
  Location: createRenderer('Location'),
  LocationWidget: createRenderer('LocationWidget'),
  Advisor: createRenderer('Advisor'),
  Article: createRenderer('Article'),
  CatwalkWidget: createRenderer('CatwalkWidget'),
  GroupedCatwalkWidget: createRenderer('GroupedCatwalkWidget'),
  AfterSalesWidget: createRenderer('AfterSalesWidget'),
  LandingPageWidget: createRenderer('LandingPageWidget'),
  DealerIntroductionWidget: createRenderer('DealerIntroductionWidget'),
  LinkBoxWidget: createRenderer('LinkBoxWidget'),
  TeamsWidget: createRenderer('TeamsWidget'),
  GalleryWidget: createRenderer('GalleryWidget'),
  DownloadsWidget: createRenderer('DownloadsWidget'),
  SimpleOfferWidget: createRenderer('SimpleOfferWidget'),
  CommonForm: createRenderer('CommonForm'),
  VehicleDesigner: createRenderer('VehicleDesigner'),
  Webform: createRenderer('Webform'),
  FooterWidget: createRenderer('FooterWidget'),
  VacsAdminCars: createRenderer('VacsAdminCars'),
  ModShowroom: createRenderer('ModShowroom'),
  LeasingAndUsedCarsWidget: createRenderer('LeasingAndUsedCarsWidget'),
  VacsAdminCampaigns: createRenderer('VacsAdminCampaigns'),
  VacsAdminPublisher: createRenderer('VacsAdminPublisher'),
  VacsAdminOfferModule: createRenderer('VacsAdminOfferModule'),
  VacsAdminManualVehicle: createRenderer('VacsAdminManualVehicle'),
  VacsAdminPriceUpload: createRenderer('VacsAdminPriceUpload'),
  WebformAdminModule: createRenderer('WebformAdminModule'),
  StandardProWidget: createRenderer('StandardProWidget'),
  StandardProWidgetText: createRenderer('StandardProWidgetText'),
  StandardProWidgetImageAndText: createRenderer('StandardProWidgetImageAndText'),
  StandardProWidgetDownloads: createRenderer('StandardProWidgetDownloads'),
  StandardProWidgetTable: createRenderer('StandardProWidgetTable'),
  StandardProWidgetGallery: createRenderer('StandardProWidgetGallery'),
  StandardProWidgetMultipleImageAndText: createRenderer('StandardProWidgetMultipleImageAndText'),
  StandardProWidgetVideo: createRenderer('StandardProWidgetVideo'),
  StandardProWidgetTabs: createRenderer('StandardProWidgetTabs'),
  StandardProWidgetBanner: createRenderer('StandardProWidgetBanner'),
  AdminImageLibrary: createRenderer('AdminImageLibrary'),
  VacsAdminReservation: createRenderer('VacsAdminReservation'),
  VacsAdminStockDataMapping: createRenderer('VacsAdminStockDataMapping'),
  BannerWidget: createRenderer('BannerWidget'),
  VacsAdminCarOffer: createRenderer('VacsAdminCarOffer'),
  PublishingOptions: createRenderer('PublishingOptions'),
  AdminTinyMCEditorWidget: createRenderer('AdminTinyMCEditorWidget'),
  VacsAdminSettings: createRenderer('VacsAdminSettings'),
  TestPageWidget: createRenderer('TestPageWidget'),
  VacsAdminCarSearch: createRenderer('VacsAdminCarSearch'),
  addEventListener,
  removeEventListener,
});

declare const window: Window & {
  DWT: typeof DWTApp;
};

if (!window.DWT) {
  window.DWT = DWTApp;
}
