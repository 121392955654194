import plusIcon from '../../../../assets/admin/admin-plus-icon.svg';
import editIcon from '../../../../assets/admin/admin-edit-icon.svg';
import editIconActive from '../../../../assets/admin/admin-edit-icon-active.svg';
import closeIcon from '../../../../assets/admin/admin-close-icon.svg';
import dragIcon from '../../../../assets/admin/admin-move.svg';
import whiteDragIcon from '../../../../assets/admin/admin-move-white.svg';
import grayDragIcon from '../../../../assets/admin/admin-move-gray.svg';
import adminDragIcon from '../../../../assets/admin/admin-draggable.svg';
import livePreviewIcon from '../../../../assets/admin/admin-preview-icon.svg';
import livePreviewGrayIcon from '../../../../assets/admin/admin-preview-gray-icon.svg';
import livePreviewGrayHiddenIcon from '../../../../assets/admin/admin-preview-gray-icon-hidden.svg';
import duplicateIcon from '../../../../assets/admin/admin-duplicate-icon.svg';
import cancelIcon from '../../../../assets/admin/admin-cancel-icon.svg';
import cancelIconWhite from '../../../../assets/admin/admin-cancel-icon-white.svg';
import approveIcon from '../../../../assets/admin/admin-approve-icon.svg';
import playIcon from '../../../../assets/admin/admin-status-play-icon.svg';
import pauseIcon from '../../../../assets/admin/admin-status-paused-icon.svg';
import disabledPlayIcon from '../../../../assets/admin/admin-status-play-disabled-icon.svg';
import sortingIcon from '../../../../assets/admin/sorting-icon.svg';
import warningIcon from '../../../../assets/admin/admin-warning-icon.svg';
import downloadIcon from '../../../../assets/admin/admin-download.svg';
import accordionUp from '../../../../assets/admin/admin-acc-up.svg';
import accordionDown from '../../../../assets/admin/admin-acc-down.svg';
import addIcon from '../../../../assets/admin/admin-add-icon.svg';
import radioIcon from '../../../../assets/admin/admin-radio-icon.svg';
import adminArrowDownIcon from '../../../../assets/admin/admin-arrow-down.svg';
import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import adminCustomScrollbarDown from '../../../../assets/admin/admin-scrollbar-down-icon.svg';
import adminCustomScrollbarUp from '../../../../assets/admin/admin-scrollbar-up-icon.svg';
import adminOfferPriceTooltipGraph from '../../../../assets/admin/admin-offer-price-tooltip-graph.svg';
import adminOfferPriceTooltipGraphIndicator from '../../../../assets/admin/admin-offer-price-tooltip-graph-indicator.svg';
import statusTooltipIcon from '../../../../assets/admin/admin-status-tooltip-icon.svg';
import adminInfoIcon from '../../../../assets/admin/admin-info-icon.svg';
import adminChevronDownIcon from '../../../../assets/admin/admin-chevron-down-icon.svg';
import adminChevronUpIcon from '../../../../assets/admin/admin-chevron-up-icon.svg';
import adminNewEditIcon from '../../../../assets/admin/admin-new-edit-icon.svg';
import adminEyeIcon from '../../../../assets/admin/admin-eye-icon.svg';
import adminCopyIcon from '../../../../assets/admin/admin-copy-icon.svg';
import adminSnippetIcon from '../../../../assets/admin/admin-snippet-icon.svg';
import adminTrashIcon from '../../../../assets/admin/admin-trash-icon.svg';
import adminNewLeftArrowIcon from '../../../../assets/admin/admin-new-arrow-left-icon.svg';
import adminNewRightArrowIcon from '../../../../assets/admin/admin-new-arrow-right-icon.svg';
import adminColumnsIcon from '../../../../assets/admin/admin-columns-icon.svg';
import approveIconBlue from '../../../../assets/admin/admin-approve-icon-blue.svg';
import adminNewCheckIcon from '../../../../assets/admin/admin-new-check-icon.svg';
import adminNewCancelIcon from '../../../../assets/admin/admin-new-cancel-icon.svg';
import adminEyeOffIcon from '../../../../assets/admin/admin-eye-off-icon.svg';
import adminNewUploadIcon from '../../../../assets/admin/admin-new-upload-cloud-icon.svg';
import adminNewExternalLinkIcon from '../../../../assets/admin/admin-new-external-link-icon.svg';
import adminNewWarningCircleIcon from '../../../../assets/admin/admin-new-warning-circle-icon.svg';
import adminNewCheckCircleIcon from '../../../../assets/admin/admin-new-check-circle-icon.svg';
import adminNewErrorIcon from '../../../../assets/admin/admin-new-error-icon.svg';
import adminNewFeaturedIcon from '../../../../assets/admin/admin-new-featured-icon.svg';
import infoIcon from '../../../../assets/admin/admin-info.svg';
import spwTextIcon from '../../../../assets/admin/spw/spw-text.svg';
import spwTextAndImageIcon from '../../../../assets/admin/spw/spw-text-and-image.svg';
import spwTextAndImageMIcon from '../../../../assets/admin/spw/spw-text-and-image-m.svg';
import spwTabs from '../../../../assets/admin/spw/spw-tabs.svg';
import spwGallery from '../../../../assets/admin/spw/spw-gallery.svg';
import spwBanner from '../../../../assets/admin/spw/spw-banner.svg';
import spwVideo from '../../../../assets/admin/spw/spw-video.svg';
import spwTable from '../../../../assets/admin/spw/spw-table.svg';
import spwDownload from '../../../../assets/admin/spw/spw-download.svg';
import minusIcon from '../../../../assets/admin/admin-minus.svg';
import searchIcon from '../../../../assets/admin/admin-search-icon.svg';
import plusGrayIcon from '../../../../assets/admin/admin-plus-gray-icon.svg';
import uploadIcon from '../../../../assets/admin/admin-upload-icon.svg';
import uploadGrayIcon from '../../../../assets/admin/admin-upload-gray-icon.svg';
import folderClosedIcon from '../../../../assets/admin/admin-folder-closed-gray icon.svg';
import folderOpenIcon from '../../../../assets/admin/admin-folder-open-gray icon.svg';
import folderClosedIconHOver from '../../../../assets/admin/admin-folder-closed icon.svg';
import editIconWhite from '../../../../assets/admin/admin-edit-icon-white.svg';
import reservedTag from '../../../../assets/admin/admin-reserved-tag.svg';
import backIcon from '../../../../assets/admin/admin-back-icon.svg';
import printIcon from '../../../../assets/admin/admin-print-icon.svg';
import xIcon from '../../../../assets/admin/admin-x-icon.svg';
import deleteIcon from '../../../../assets/admin/admin-delete-icon.svg';
import darkDragIcon from '../../../../assets/admin/admin-dark-drag-icon.svg';
import cianDragIcon from '../../../../assets/admin/admin-cian-drag-icon.svg';
import bannerEditIcon from '../../../../assets/admin/banner/banner-edit-icon.svg';
import bannerEditIconDisabled from '../../../../assets/admin/banner/banner-edit-icon-disabled.svg';
import bannerDeleteIcon from '../../../../assets/admin/banner/banner-delete-icon.svg';
import bannerInfoIcon from '../../../../assets/admin/banner/admin-banner-info.svg';
import bannerAddIcon from '../../../../assets/admin/banner/banner-add.svg';
import adminImageLibUpload from '../../../../assets/admin/admin-image-lib-upload.svg';
import spwEditIcon from '../../../../assets/admin/spw/spw-edit-icon-white.svg';
import spwInfoIcon from '../../../../assets/admin/spw/spw-info-icon-white.svg';
import exportIcon from '../../../../assets/admin/admin-export-icon.svg';
import uploadHistoryIcon from '../../../../assets/admin/admin-upload-history-icon.svg';
import exitIcon from '../../../../assets/admin/admin-exit-icon.svg';
import editIconGray from '../../../../assets/admin/admin-edit-icon-gray.svg';
import adminFilterIcon from '../../../../assets/admin/admin-filter-icon.svg';
import adminGrayLeftArrowIcon from '../../../../assets/admin/admin-gray-arrow-left-icon.svg';
import xBlueIcon from '../../../../assets/admin/publishing-options/x-blue-icon.svg';
import adminCancelIconBlue from '../../../../assets/admin/admin-cancel-icon-blue.svg';
import adminPlusBlueIcon from '../../../../assets/admin/admin-plus-blue-icon.svg';
import adminChevronUpIconLightGray from '../../../../assets/admin/admin-chevron-up-icon-light-gray.svg';
import adminChevronDownIconLightGray from '../../../../assets/admin/admin-chevron-down-icon-light-gray.svg';
import adminNewDeleteIcon from '../../../../assets/admin/admin-new-delete-icon.svg';
import adminNewEditIconActive from '../../../../assets/admin/admin-new-edit-icon-active.svg';
import adminPlusIcon from '../../../../assets/admin/admin-plus-icon.svg';
import spwChevronDownDarkBlue from '../../../../assets/admin/spw/spw-chevron-down-dark-blue.svg';
import spwChevronUpDarkBlue from '../../../../assets/admin/spw/spw-chevron-up-dark-blue.svg';
import spwChevronDownLightBlue from '../../../../assets/admin/spw/spw-chevron-down-light-blue.svg';
import spwChevronUpLightBlue from '../../../../assets/admin/spw/spw-chevron-up-light-blue.svg';
import spwDraggableIcon from '../../../../assets/admin/spw/spw-draggable-icon.svg';
import spwRefreshArrows from '../../../../assets/admin/spw/spw-refresh-arrows.svg';
import adminRefreshIcon from '../../../../assets/admin/admin-refresh-icon.svg';
import adminGlobeIcon from '../../../../assets/admin/admin-globe.svg';
import adminMagnificentIcon from '../../../../assets/admin/admin-magnificent-icon.svg';
import adminCSWGlobeIcon from '../../../../assets/admin/car-search-widget/admin-globe-icon.svg';
import adminCSWDeleteIcon from '../../../../assets/admin/car-search-widget/admin-delete-icon.svg';
import adminCSWEditIcon from '../../../../assets/admin/car-search-widget/admin-edit-icon.svg';
import adminCSWPreviewIcon from '../../../../assets/admin/car-search-widget/admin-preview-icon.svg';
import adminCSWSnippetIcon from '../../../../assets/admin/car-search-widget/admin-snippet-icon.svg';
import spwExportIcon from '../../../../assets/admin/spw/spw-export-icon.svg';
import spwImportIcon from '../../../../assets/admin/spw/spw-import-icon.svg';
import adminArrowDragIcon from '../../../../assets/admin/admin-arow-drag-icon.svg';
import adminCianArrowDragIcon from '../../../../assets/admin/admin-cian-arow-drag-icon.svg';
import adminNewExportIcon from '../../../../assets/admin/admin-new-export-icon.svg';
import adminNewImportIcon from '../../../../assets/admin/admin-new-import-icon.svg';

export const adminAssets = Object.freeze({
  adminRefreshIcon,
  adminGlobeIcon,
  adminArrowDragIcon,
  adminCianArrowDragIcon,
  adminMagnificentIcon,
  spwEditIcon,
  spwInfoIcon,
  bannerEditIcon,
  adminImageLibUpload,
  bannerAddIcon,
  bannerInfoIcon,
  bannerEditIconDisabled,
  bannerDeleteIcon,
  plusIcon,
  minusIcon,
  editIcon,
  editIconActive,
  closeIcon,
  dragIcon,
  whiteDragIcon,
  darkDragIcon,
  cianDragIcon,
  grayDragIcon,
  livePreviewIcon,
  livePreviewGrayIcon,
  duplicateIcon,
  cancelIcon,
  cancelIconWhite,
  approveIcon,
  playIcon,
  pauseIcon,
  disabledPlayIcon,
  sortingIcon,
  downloadIcon,
  accordionUp,
  accordionDown,
  addIcon,
  radioIcon,
  noImagePlaceholder,
  warningIcon,
  infoIcon,
  spwTextIcon,
  spwTextAndImageIcon,
  spwTextAndImageMIcon,
  spwTabs,
  spwGallery,
  spwBanner,
  spwVideo,
  spwTable,
  spwDownload,
  searchIcon,
  plusGrayIcon,
  uploadIcon,
  uploadGrayIcon,
  folderClosedIcon,
  folderOpenIcon,
  folderClosedIconHOver,
  livePreviewGrayHiddenIcon,
  adminArrowDownIcon,
  adminCustomScrollbarDown,
  adminCustomScrollbarUp,
  adminOfferPriceTooltipGraph,
  adminOfferPriceTooltipGraphIndicator,
  statusTooltipIcon,
  adminInfoIcon,
  adminChevronDownIcon,
  adminChevronUpIcon,
  adminNewEditIcon,
  adminEyeIcon,
  adminCopyIcon,
  adminSnippetIcon,
  adminTrashIcon,
  adminNewLeftArrowIcon,
  adminNewRightArrowIcon,
  adminColumnsIcon,
  approveIconBlue,
  adminNewCheckIcon,
  adminNewCancelIcon,
  adminEyeOffIcon,
  adminNewUploadIcon,
  adminNewExternalLinkIcon,
  adminNewWarningCircleIcon,
  adminNewCheckCircleIcon,
  adminNewErrorIcon,
  adminNewFeaturedIcon,
  editIconWhite,
  reservedTag,
  backIcon,
  printIcon,
  xIcon,
  deleteIcon,
  adminDragIcon,
  exportIcon,
  uploadHistoryIcon,
  exitIcon,
  editIconGray,
  adminFilterIcon,
  adminGrayLeftArrowIcon,
  xBlueIcon,
  adminCancelIconBlue,
  adminPlusBlueIcon,
  adminChevronUpIconLightGray,
  adminChevronDownIconLightGray,
  adminCSWGlobeIcon,
  adminCSWDeleteIcon,
  adminCSWEditIcon,
  adminCSWPreviewIcon,
  adminCSWSnippetIcon,
  adminNewDeleteIcon,
  adminNewEditIconActive,
  adminPlusIcon,
  spwChevronDownDarkBlue,
  spwChevronUpDarkBlue,
  spwChevronDownLightBlue,
  spwChevronUpLightBlue,
  spwDraggableIcon,
  spwRefreshArrows,
  spwExportIcon,
  spwImportIcon,
  adminNewExportIcon,
  adminNewImportIcon,
});
