import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import peugeotLogo from '../../../../assets/peugeot/icon/peugeot_logo_index.png';
import blueCheckMarkIcon from '../../../../assets/peugeot/icon/blue-check-mark.svg';
import peuCalendar from '../../../../assets/peugeot/icon/calendrier_50x50.svg';
import fuelIcon from '../../../../assets/peugeot/icon/carburant.svg';
import peuCheckIcon from '../../../../assets/peugeot/icon/check_50x50.svg';
import chevronDownIcon from '../../../../assets/peugeot/icon/chevron-down.svg';
import chevronRight from '../../../../assets/peugeot/icon/chevron-right.svg';
import chevronLeft from '../../../../assets/peugeot/icon/chevron-left.svg';
import closeIcon from '../../../../assets/peugeot/icon/close_icon24x24.svg';
import emissionCo2Icon from '../../../../assets/peugeot/icon/emissions_de_CO2.svg';
import peuPoiActive from '../../../../assets/peugeot/icon/peu-poi-active.svg';
import peuPoiInactive from '../../../../assets/peugeot/icon/peu-poi-inactive.svg';
import powerIcon from '../../../../assets/peugeot/icon/power.svg';
import peuBusinessToBusinessIcon from '../../../../assets/peugeot/icon/services/businesstobusiness.svg';
import peuExclusiveAmperaIcon from '../../../../assets/peugeot/icon/services/carosserie.svg';
import peuCarRentalIcon from '../../../../assets/peugeot/icon/services/carrental.svg';
import peuCarWashIcon from '../../../../assets/peugeot/icon/services/carwash.svg';
import peuCentralContactIcon from '../../../../assets/peugeot/icon/services/centralcontact.svg';
import peuCommercialCarsSalesIcon from '../../../../assets/peugeot/icon/services/commercialsales.svg';
import peuPaintAndBodyRepairIcon from '../../../../assets/peugeot/icon/services/paintandbodyrepair.svg';
import peuPartsStoreIcon from '../../../../assets/peugeot/icon/services/parts.svg';
import peuPassengerCarsSalesIcon from '../../../../assets/peugeot/icon/services/passengercarsales.svg';
import peuCommercialCarsServiceIcon from '../../../../assets/peugeot/icon/services/service.svg';
import peuPeugeotSportsEngineeredIcon from '../../../../assets/peugeot/icon/services/peugeotsportsengineered.png';
import peuUsedCarsIcon from '../../../../assets/peugeot/icon/services/usedcars.svg';
import peuGearTypeIcon from '../../../../assets/peugeot/icon/shift_numbers.svg';
import peuPhone from '../../../../assets/peugeot/icon/telephone.svg';
import vehicleFrontIcon from '../../../../assets/peugeot/icon/voiture_face.svg';
import whiteChevronLeftIcon from '../../../../assets/peugeot/icon/white-chevron-left.svg';
import onMapIcon from '../../../../assets/peugeot/icon/on_map.svg';
import emailIcon from '../../../../assets/peugeot/icon/emailIcon.svg';
import infoIcon from '../../../../assets/peugeot/icon/infoIcon.svg';
import phoneIcon from '../../../../assets/peugeot/icon/phone.svg';
import plusIcon from '../../../../assets/peugeot/icon/plus.svg';
import minusIcon from '../../../../assets/peugeot/icon/minus.svg';
import backArrowIcon from '../../../../assets/peugeot/icon/back_arrow.svg';
import fullStar from '../../../../assets/peugeot/icon/fullStar.svg';
import halfStar from '../../../../assets/peugeot/icon/halfStar.svg';
import emptyStar from '../../../../assets/peugeot/icon/emptyStar.svg';
import formDateInput from '../../../../assets/peugeot/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/peugeot/icon/form-time-input.svg';
import deleteIcon from '../../../../assets/peugeot/icon/delete.svg';
import docExtension from '../../../../assets/peugeot/icon/extension.svg';
import prevArrowLightWithBackground from '../../../../assets/peugeot/icon/prevArrowLightWithBackground.svg';
import nextArrowLightWithBackground from '../../../../assets/peugeot/icon/nextArrowLightWithBackground.svg';
import blackInfoIcon from '../../../../assets/peugeot/icon/blackInfoIcon.svg';

export const peuAssets = Object.freeze({
  closeIcon,
  fuelIcon,
  emissionCo2Icon,
  noImagePlaceholder,
  peugeotLogo,
  peuBusinessToBusinessIcon,
  peuCalendar,
  peuCarRentalIcon,
  peuCarWashIcon,
  peuCentralContactIcon,
  peuCheckIcon,
  peuCommercialCarsSalesIcon,
  peuCommercialCarsServiceIcon,
  peuExclusiveAmperaIcon,
  peuGearTypeIcon,
  peuPaintAndBodyRepairIcon,
  peuPassengerCarsSalesIcon,
  peuPeugeotSportsEngineeredIcon,
  peuPassengerCarsServiceIcon: peuCommercialCarsServiceIcon,
  peuPartsStoreIcon,
  peuPhone,
  peuPoiActive,
  peuPoiInactive,
  peuUsedCarsIcon,
  powerIcon,
  vehicleFrontIcon,
  chevronDownIcon,
  chevronRight,
  chevronLeft,
  blueCheckMarkIcon,
  whiteChevronLeftIcon,
  onMapIcon,
  emailIcon,
  phoneIcon,
  infoIcon,
  plusIcon,
  minusIcon,
  backArrowIcon,
  fullStar,
  halfStar,
  emptyStar,
  formDateInput,
  formTimeInput,
  deleteIcon,
  docExtension,
  prevArrowLightWithBackground,
  nextArrowLightWithBackground,
  blackInfoIcon,
});
